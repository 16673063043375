import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Link, useLocation } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import HeaderNavMenu from "./HeaderNavMenu";
import cn from "classnames";
import { GiHamburgerMenu } from "react-icons/gi";
import { API, URL } from "../../config/urls";
import axios from "axios";

const HeaderOne = ({ headerClassName, setLoading }) => {
  const dashUrl = "http://app.electionforfree.com";

  const { pathname } = useLocation();

  const isActiveClassName = (path) => {
    return path === pathname ? "active" : "";
  };

  const [data, setData] = useState();
  useEffect(() => {
    const Getnav = () => {
      var config = {
        method: "get",
        url: API.getheader,
        headers: {},
      };
      axios(config)
        .then(function (response) {
          console.log(response.data);
          setData(response.data?.[0]);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    Getnav();
  }, [pathname, setLoading]);
  useEffect(() => {
    $(window).on("scroll", function () {
      var scroll = $(window).scrollTop();
      if (scroll < 245) {
        $("#sticky-header").removeClass("sticky-menu");
        $(".scroll-to-target").removeClass("open");
        $("#header-fixed-height").removeClass("active-height");
      } else {
        $("#sticky-header").addClass("sticky-menu");
        $(".scroll-to-target").addClass("open");
        $("#header-fixed-height").addClass("active-height");
      }
    });
  }, []);

  return (
    <header className={cn(headerClassName)}>
      <div id="header-fixed-height" />
      <div id="sticky-header" className="menu-area new-menu">
        <div className="logo ">
          <Link to="/">
            <img
              src={URL + data?.logo}
              style={{ maxHeight: "60px", objectFit: "contain" }}
              alt="Logo"
            />
          </Link>
        </div>
        <div className="">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler">
                {/* <i className="fas fa-bars"></i> */}
                <GiHamburgerMenu color="var(--tg-blue)" />
              </div>

              <div className="menu-wrap">
                <nav className="menu-nav">
                  <HeaderNavMenu
                    setLoading={setLoading}
                    data={data}
                    isActiveClassName={isActiveClassName}
                    pathname={pathname}
                  />
                  <div className="header-action d-none d-md-block">
                    <ul className="list-wrap">
                      {data?.custom_url?.map((item, index) => (
                        <li key={index}>
                          {index === 0 && item.custom_url[0] && (
                            <Link
                              to={`${dashUrl}/${item.custom_url[0]?.link}`}
                              target="_blank"
                              className="btn px-4 py-3 mt-3 mt-sm-0 fadeInRight"
                              style={{
                                borderRadius: "30px",
                                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
                                color: "var(--tg-primary-color)",
                                background: "var(--tg-white)",
                              }}
                            >
                              {item.custom_url[0]?.name}
                            </Link>
                          )}
                          {index === 1 && item.custom_url[1] && (
                            <Link
                              to={`${dashUrl}/${item.custom_url[1]?.link}`}
                              target="_blank"
                              className="btn px-4 py-3 mt-3 mt-sm-0 fadeInRight"
                              style={{
                                borderRadius: "30px",
                                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
                                color: "var(--tg-white)",
                                background: "var(--tg-primary-color)",
                              }}
                            >
                              {item.custom_url[1]?.name}
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                </nav>
              </div>
              {/* 
              <!-- Mobile Menu  --> */}
              <MobileMenu />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderOne;
