import React from "react";
import ContactForm from "./ContactForm";
import { motion } from "framer-motion";

const ContactPageArea = ({ data }) => {
  return (
    <section className="contact-area pt-130">
      <div className="container">
        <div className="contact-form-area mb-5 ">
          <div className="row align-items-center g-0">
            <div className="col-lg-6">
              <div className="contact-img">
                <motion.img
                  src={
                    data?.image
                      ? data?.image
                      : "https://d1ldvf68ux039x.cloudfront.net/thumbs/photos/2009/6373697/1000w_q95.jpg"
                  }
                  alt="contact"
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5 }}
                  className="img-fluid rounded shadow-lg"
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="contact-content">
                <h2 className="title">{data?.title}</h2>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="row justify-content-center">
            {data?.custom_fields.map((contact, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-10">
                <div className="inner-contact-info-item">
                  <div className="icon">
                    <i className={contact.name}></i>
                  </div>
                  <div className="content">
                    <a href={contact.href}>{contact.name}</a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPageArea;
