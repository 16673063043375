import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";

const HeaderNavMenu = ({ setLoading, data, isActiveClassName, pathname }) => {
  return (
    <div className="navbar-wrap main-menu d-none d-lg-flex">
      <ul className="navigation">
        {data &&
          data.contents.map((x) => (
            <li
              key={x.id}
              style={{ cursor: "pointer" }}
              className={cn("menu-item-has-children ", {
                active: x.content.some((d) =>
                  pathname.includes(d.title.toLowerCase().replace(/\s/g, ""))
                ),
              })}
            >
              {x.content.length > 0 ? (
                <>
                  <div>{x.name}</div>
                  <ul className="sub-menu">
                    {x.content.map((d) => (
                      <li key={d.id} className={cn(isActiveClassName(d.title))}>
                        <Link
                          to={"/" + d.title.toLowerCase().replace(/\s/g, "-")}
                        >
                          {d.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <>
                  <Link
                    to={
                      "/" + x.content[0].title.toLowerCase().replace(/\s/g, "-")
                    }
                  >
                    {x.name}
                  </Link>
                </>
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default HeaderNavMenu;
