import React, { useEffect, useState } from "react";
import TeamDetailsForm from "./TeamDetailsForm";
// import TeamDetailsProgress from "./TeamDetailsProgress";
import TeamDetailsSidebar from "./TeamDetailsSidebar";
import TeamDetailsSlider from "./TeamDetailsSlider";
import axios from "axios";
import { API } from "../../../config/urls";
import { useParams } from "react-router-dom";

const TeamDetailsArea = () => {
  const [doctorList,setDoctorList] = useState()
  const [doctor, setDoctor] = useState()
  const {id} = useParams()
 

  useEffect(() => {
    const GetDoctorList = () => {
      var config = {
        method: "get",
        url: API.getDoctorList,
        headers: {},
      };
      axios(config)
        .then(function (response) {
          console.log(response);
          setDoctor(response.data?.all_doctors?.[id]);
          setDoctorList(response.data?.all_doctors);
        })
  
        .catch(function (error) {
          console.log(error);
        });
    };
    GetDoctorList()
  }, [id]);
  return (
    <section className="team-details-area pt-130 pb-100">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-md-8">
            {/* team details sidebar */}
            <TeamDetailsSidebar data={doctor} />
          </div>

          <div className="col-lg-8">
            <div className="team-details-content">
              <p>
              As a compassionate and dedicated physician, Dr. William Adams brings a wealth of expertise to our team. Specializing in Dental, Dr. William Adams is committed to providing personalized care and empowering patients to achieve their health goals. 
              </p>
              <p>
              With a focus on empathy and communication, Dr. William Adams builds strong patient-doctor relationships, ensuring exceptional care and support at every step of the journey.
              </p>

              {/* <TeamDetailsProgress /> */}

             <div className="team-details-form">
                <h2 className="title">
                  Book an Appointment
                </h2>
                <TeamDetailsForm doctorId={id} />
              </div> 
            </div>
          </div>
        </div>

        <div className="related-member-wrap">
          {/* team details slider */}
          <TeamDetailsSlider data={doctorList} />
        </div>
      </div>
    </section>
  );
};

export default TeamDetailsArea;
