import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API, URL } from "../../config/urls";
import FooterBottom from "./FooterBottom";
import Newsletter from "./Newsletter";

const FooterOne = () => {
  const [footerData, setFooterData] = useState([]);
  useEffect(() => {
    fetchFooterData();
  }, []);

  const fetchFooterData = () => {
    axios
      .get(API.getfooter)
      .then((response) => {
        setFooterData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching footer data:", error);
      });
  };

  return (
    <footer>
      <div className="footer-area footer-bg">
        <div className="footer-top">
          <div className="container">
            <div className="footer-logo-area">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src={URL + footerData[0]?.logo}
                        alt="Logo"
                        style={{ maxWidth: "100%", height: "80px" }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="footer-social-menu">
                    <ul className="list-wrap">
                      {footerData[0]?.custom_url[0]?.custom_url.map(
                        (section) => (
                          <li key={section.id}>
                            <a
                              href={`${section.link}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {section.name}
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {/* Sections */}
              {footerData[0]?.contents.map((section) => (
                <div key={section.id} className="col-lg-3 col-sm-6">
                  <div className="footer-widget">
                    <div className="fw-title">
                      <h4 className="title">{section.name}</h4>
                    </div>
                    <div className="fw-link-list">
                      <ul className="list-wrap">
                        {section.content.map((content) => (
                          <li key={content.id}>
                            <Link
                              to={`/${content.title
                                .toLowerCase()
                                .replace(/\s+/g, "-")}`}
                            >
                              {content.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
              <Newsletter />
              {/* Custom URLs */}
              {footerData[0]?.custom_url.map((customSection) => (
                <div key={customSection.id} className="col-lg-3 col-sm-6">
                  <div className="footer-widget">
                    <div className="fw-title">
                      <h4 className="title">{customSection.name}</h4>
                    </div>
                    <div className="fw-link-list">
                      <ul className="list-wrap">
                        {customSection.custom_url.map((customUrl) => (
                          <li key={customUrl.id}>
                            <Link to={`/${customUrl.link}`}>
                              {customUrl.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <FooterBottom />
      </div>
    </footer>
  );
};

export default FooterOne;
