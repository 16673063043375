import React from "react";
import { Link } from "react-router-dom";

const TeamOneItem = ({ item, index }) => {
  return (
    <div className="team-item">
      <div className="team-thumb">
        <Link to={"/doctor-details/" + index}>
          <img
            src={"/img/telehealth/doctor3.jpg"}
            style={{ width: "100%" }}
            alt=""
          />
        </Link>
      </div>

      <Link to={"/doctor-details/" + index}>
        <div className="team-content">
          <h3 className="title">
            {item.first_name} {item.last_name}
          </h3>
          <span>{item.speciality}</span>
          {/* <div className="team-social">
          <span className="social-toggle-icon">
            <i className="fas fa-share-alt"></i>
          </span>
          <ul className="list-wrap">
            <li>
              <a href="/">
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
            <li>
              <a href="/">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
          </ul>
        </div> */}
        </div>
      </Link>
    </div>
  );
};

export default TeamOneItem;
