import axios from "axios";
import React, { useState } from "react";
import { API } from "../../config/urls";
import toast from "react-hot-toast";

const Newsletter = () => {
  const [input, setInput] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(API.postNewsletter, input)
      .then((res) => {
        toast.success("Form submitted successfully");
        setInput({ email: "" });
      })
      .catch((err) => toast.error("Form not submitted"));
  };
  return (
    <div className="col-lg-3 col-sm-6">
      <div className="footer-widget">
        <div className="fw-title">
          <h4 className="title">Newsletter</h4>
        </div>
        <div className="footer-newsletter">
          <p>Subscribe to our newsletter to get our latest updates and news</p>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Your email address"
              onChange={handleChange}
              name="email"
              value={input.email}
            />
            <button type="submit" className="btn">
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
