import React, { useEffect } from "react";
import { bgImgFromData } from "../../lib/helpers";
import { jarallax } from "jarallax";

const ServicesAreaThree = ({ data }) => {
  useEffect(() => {
    bgImgFromData();
  }, []);

  // jarallax
  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);

  return (
    <section className="services-area-three pt-125">
      <div className="services-bg jarallax"></div>
      <div className="container custom-container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7">
            <div className="section-title-two  text-center mb-65 tg-heading-subheading animation-style2">
              <span className="sub-title">{data?.sub_heading}</span>
              <h2 className="title tg-element-title">{data?.heading}</h2>
            </div>
          </div>
        </div>

        <div className="container ">
          <div className="row g-4">
            {data?.custom_fields.slice(0,6).map((item, index) => (
              <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-4">
                <div className="card h-100 service-card">
                  <div className="card-body d-flex flex-column " style={{textAlign:"justify"}}>
                    <h5 className="card-title">{item.name}</h5>
                    <p className="card-text text-justify">
                      {item.custom_field_collections[0].char.slice(0, 300) ||
                        item.custom_field_collections[0].text.slice(0, 300)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesAreaThree;
