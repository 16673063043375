import { useForm } from "react-hook-form";
import { API } from "../../config/urls";
import toast from "react-hot-toast";

import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";

const schema = z
  .object({
    organization_name: z.string().nonempty("You must enter your org name"),
    address1: z.string().nonempty("You must enter Address 1"),
    zip_code: z.string().nonempty("You must enter the Zip Code"),
    state: z.string().nonempty("You must enter the State"),
    city: z.string().nonempty("You must enter the City"),
    first_name: z.string().nonempty("You must enter your first name"),
    last_name: z.string().nonempty("You must enter your last name"),
    email: z
      .string()
      .email("You must enter a valid email")
      .nonempty("You must enter an email"),
    phone_number: z.string().nonempty("You must enter your phone name"),
    acceptTermsConditions: z
      .boolean()
      .refine(
        (val) => val === true,
        "The terms and conditions must be accepted."
      ),
    password: z
      .string()
      .nonempty("Please enter your password.")
      .min(8, "Password is too short - should be 8 chars minimum."),
    passwordConfirm: z.string().nonempty("Password confirmation is required"),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "Passwords must match",
    path: ["passwordConfirm"],
  });

const defaultValues = {
  organization_name: "",
  address1: "",
  address2: "",
  zip_code: "",
  state: "",
  city: "",
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  acceptTermsConditions: false,
  password: "",
  passwordConfirm: "",
};

export default function SignUpPage() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: zodResolver(schema),
  });

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    const payload = {
      organization_name: data.organization_name,
      address1: data.address1,
      address2: data.address2,
      zip_code: data.zip_code,
      state: data.state,
      city: data.city,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      phone_number: data.phone_number,
      acceptTermsConditions: data.acceptTermsConditions,
      password: data.password,
      passwordConfirm: data.passwordConfirm,
      signup_form: true,
    };

    try {
      const response = await fetch(API.postSignup, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        toast.success("Form Submitted Successfully");
        reset();
        navigate('https://dashboard.electionforfree.com/login');
      } else {
        const errorData = await response.json();
        console.log(errorData);
        toast.error(errorData.message);
      }
    } catch (error) {
      console.log(error, payload);
      toast.error("Form not Submitted");
    }
  };

  return (
    <>
      <div
        style={{ paddingLeft: "10vw", paddingRight: "10vw", paddingTop: 40 }}
      >
        <div className="row py-4">
          <div className="col-md-8 ">
            <>
              <div className="col container shadow py-4 my-3 m-auto rounded p-5">
                <h3 className="fw-bold">Create Your Account</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row mb-3">
                    <h6 style={{ marginTop: 40 }}>Account Detail</h6>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start"
                          placeholder="First Name"
                          {...register("first_name")}
                        />
                        {errors.first_name && (
                          <div className="text-danger">
                            Please enter your first name
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start"
                          placeholder="Last Name"
                          {...register("last_name")}
                        />
                        {errors.last_name && (
                          <div className="text-danger">
                            Please enter your last name
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start"
                          placeholder="Email"
                          {...register("email")}
                        />
                        {errors.email && (
                          <div className="text-danger">
                            Please enter a valid email
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control form-control-lg text-start"
                          placeholder="Phone Number"
                          {...register("phone_number")}
                        />
                        {errors.phone_number && (
                          <div className="text-danger">
                            Please enter your phone number
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <input
                          type="password"
                          className="form-control form-control-lg text-start"
                          placeholder="Password"
                          {...register("password")}
                        />
                        {errors.password && (
                          <div className="text-danger">
                            {errors.password.message}
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <input
                          type="password"
                          className="form-control form-control-lg text-start"
                          placeholder="Confirm Password"
                          {...register("passwordConfirm")}
                        />
                        {errors.passwordConfirm && (
                          <div className="text-danger">
                            {errors.passwordConfirm.message}
                          </div>
                        )}
                      </div>
                    </div>
                    <h6 style={{ marginTop: 40 }}>Organization Detail</h6>
                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control form-control-lg text-start"
                        placeholder="Office Name"
                        {...register("organization_name")}
                      />
                      {errors.organization_name && (
                        <div className="text-danger">
                          Please enter the office name
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control form-control-lg text-start"
                        placeholder="Address 1"
                        {...register("address1")}
                      />
                      {errors.address1 && (
                        <div className="text-danger">
                          Please enter Address 1
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <input
                        type="text"
                        className="form-control form-control-lg text-start"
                        placeholder="Zip Code"
                        {...register("zip_code")}
                      />
                      {errors.zip_code && (
                        <div className="text-danger">
                          Please enter the Zip Code
                        </div>
                      )}
                    </div>
                    <div className="col-md-4">
                      <input
                        type="text"
                        className="form-control form-control-lg text-start"
                        placeholder="State"
                        {...register("state")}
                      />
                      {errors.state && (
                        <div className="text-danger">
                          Please enter the State
                        </div>
                      )}
                    </div>
                    <div className="col-md-4">
                      <input
                        type="text"
                        className="form-control form-control-lg text-start"
                        placeholder="City"
                        {...register("city")}
                      />
                      {errors.city && (
                        <div className="text-danger">Please enter the City</div>
                      )}
                    </div>
                  </div>

                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      {...register("acceptTermsConditions")}
                    />
                    <label className="form-check-label">
                      Accept Terms and Conditions
                    </label>
                    {errors.acceptTermsConditions && (
                      <div className="text-danger">
                        Please accept the terms and conditions
                      </div>
                    )}
                  </div>
                  <div className="text-end w-100" style={{ width: "100%" }}>
                    <button
                      type="submit"
                      className="btn px-4 py-3 text-white mt-3 mt-sm-0 fadeInRight"
                      style={{
                        borderRadius: "30px",
                        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
                        width: "100%",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </>
          </div>
          <div className="col overflow-hidden">
            <img
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)",
              }}
              src="https://img.freepik.com/premium-vector/illustration-vector-graphic-cartoon-character-online-registration_516790-1809.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}
