import { jarallax } from "jarallax";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { URL } from "../../config/urls";
import { motion } from "framer-motion";

const BannerTwo = ({ data }) => {
  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);

  const bg = data?.image?.[0]?.image;
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <section
      className="d-flex align-items-center justify-content-between w-100 position-relative"
      style={{ height: "80vh",  overflow: "hidden", background:"#F3F9FE" }}
    >
      <motion.div
        className="col-lg-6 col-md-8 mx-auto mb-4 mb-md-0 text-center text-md-left container"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <motion.h6
          className="title tg-element-title"
          style={{ fontWeight: "700", color: "#BD2131" }}
          variants={itemVariants}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          {data?.heading}
        </motion.h6>
        <motion.h2
          className="title tg-element-title"
          style={{ fontSize: "2.5rem", fontWeight: "700" }}
          variants={itemVariants}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          {data?.heading}
        </motion.h2>

        <motion.div
          className="mt-4"
          dangerouslySetInnerHTML={{ __html: data?.description }}
          style={{ fontSize: "1.1rem", lineHeight: "1.6" }}
          variants={itemVariants}
          transition={{ duration: 1, ease: "easeInOut" }}
        ></motion.div>

        {data?.custom_url?.[0]?.name && (
          <motion.div
            variants={itemVariants}
            transition={{ duration: 1, ease: "easeInOut" }}
            className="mt-4"
          >
            <Link
              to={`/${data?.custom_url?.[0].link}`}
              className="btn px-5 py-3 text-white"
              style={{
                borderRadius: "30px",
                boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
                background: "var(--tg-secondary-color)",
                transition: "background 0.3s ease, transform 0.3s ease",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.transform = "scale(1.05)")
              }
              onMouseOut={(e) => (e.currentTarget.style.transform = "scale(1)")}
            >
              {data?.custom_url?.[0].name} Learn More
            </Link>
          </motion.div>
        )}
      </motion.div>

      <motion.div
        className="col-lg-6 col-md-8 h-100 clipped jarallax position-relative"
        style={{
          minHeight: "350px",
          backgroundImage: bg
            ? `url(${URL + bg})`
            : "url('https://youth.europa.eu/d8/sites/default/files/styles/1200x600/public/2024-01/voting.png?itok=ibzOMTVc')",
          backgroundPosition: "center",
          backgroundSize: "cover",
          borderRadius: "20px",
        }}
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <motion.div
          className="position-absolute w-100 h-100"
          style={{
            background:
              "linear-gradient(45deg, rgba(255, 0, 0, 0.2), rgba(255, 0, 128, 0.15), rgba(128, 0, 255, 0.1), rgba(0, 0, 255, 0.05))",
            top: 0,
            left: 0,
            borderRadius: "20px",
          }}
          variants={itemVariants}
          transition={{ duration: 1, ease: "easeInOut" }}
        />
      </motion.div>
    </section>
  );
};

export default BannerTwo;
