import React, { useEffect } from "react";
import SlickSlider from "../SlickSlider/SlickSlider";
import { jarallax } from "jarallax";

const ServicesAreaFour = ({ data }) => {
  useEffect(() => {
    // Initialize jarallax on component mount
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);

  const slick_settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section className="services-area-three pt-125">
      <div className="services-bg jarallax"></div>
      <div className="container custom-container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7">
            <div className="section-title-two text-center mb-65 tg-heading-subheading animation-style2">
              <span className="sub-title">{data?.sub_heading}</span>
              <h2 className="title tg-element-title">{data?.heading}</h2>
            </div>
          </div>
        </div>

        <div className="services-item-wrap-two">
          <div className="row services-active">
            <SlickSlider settings={slick_settings}>
              {data?.custom_fields.map((item, index) => (
                <div key={index} className="col-lg-3">
                  <div className="services-item-four">
                    <div className="services-content-four">
                      <h3 className="title">{item?.name}</h3>
                      {item?.custom_field_collections.map((collection, idx) => (
                        <p key={idx}>{collection.char.slice(0,200)}</p>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </SlickSlider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesAreaFour;
