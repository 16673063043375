import axios from "axios";
import React, { useEffect, useState } from "react";
import { API } from "../../config/urls";
import { Link } from "react-router-dom";

const PricingTable = () => {
  const [pricingData, setPricingData] = useState([]);
  console.log(pricingData);
  useEffect(() => {
    axios
      .get(API.getPricing)
      .then((response) => {
        setPricingData(response.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="service-area-two container">
      <section id="generic_price_table">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title-two text-center mb-30 tg-heading-subheading animation-style1">
              <span className="sub-title">Our Pricing</span>
              <h2 className="title tg-element-title">
                Choose Your Pricing Plan
              </h2>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            {pricingData?.map((pricingPlan, index) => (
              <PriceColumn
                key={index}
                title={pricingPlan.name}
                price={pricingPlan.price}
                features={pricingPlan.voters_limit}
                active={pricingPlan.duration_in_days}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

const PriceColumn = ({ title, price, features, active }) => {
  return (
    <div className={`col-md-4 ${active ? "active" : ""}`}>
      <div className="generic_content clearfix">
        <div className="generic_head_price clearfix">
          <div className="generic_head_content clearfix">
            <div className="head_bg"></div>
            <div className="head">
              <span>{title}</span>
            </div>
          </div>
          <div className="generic_price_tag clearfix">
            <span className="price">
              <span className="sign">$</span>
              <span className="currency">{price}</span>
              <span className="cent"></span>
              <span className="month">/MON</span>
            </span>
          </div>
        </div>
        <div className="generic_feature_list">
          <ul>
            <li>
              <span>Up To {features} Voters</span>
            </li>
          </ul>
        </div>

        <div className="generic_price_btn clearfix">
          <Link className="" to="/signup">
            Sign up
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PricingTable;
