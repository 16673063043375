import React, { useState } from "react";
import axios from "axios";
import { API } from "../../config/urls";
import toast from "react-hot-toast";

const ContactForm = () => {
  const [input, setInput] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setInput((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(API.postContact, input)
      .then((res) => {
        toast.success("Form submitted successfully");
        setInput({ name: "", email: "", phone: "", subject: "", message: "" });
      })
      .catch((err) => toast.error("Form not submitted"));
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-grp">
            <input
              type="text"
              name="name"
              value={input.name}
              onChange={(e) => handleChange(e)}
              placeholder="Your Name"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-grp">
            <input
              type="email"
              name="email"
              value={input.email}
              onChange={(e) => handleChange(e)}
              placeholder="Email address"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-grp">
            <input
              type="text"
              name="phone"
              value={input.phone}
              onChange={(e) => handleChange(e)}
              placeholder="Phone number"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-grp">
            <input
              type="text"
              name="subject"
              value={input.subject}
              onChange={(e) => handleChange(e)}
              placeholder="Subject"
            />
          </div>
        </div>
      </div>
      <div className="form-grp">
        <textarea
          name="message"
          value={input.message}
          onChange={(e) => handleChange(e)}
          placeholder="Write message"
        ></textarea>
      </div>
      <button type="submit" className="login-btn w-100">
        Send a message
      </button>
    </form>
  );
};

export default ContactForm;
