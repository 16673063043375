import React from "react";

const TestimonialAreaOneItem = ({ item }) => {
  console.log(item);
  return (
    <div className="testimonial-item ">
      <div className="testimonial-icon">
        <i className="fas fa-quote-right"></i>
      </div>

      <div className="testimonial-content">
        <p>{item.custom_field_collections[0].char}</p>

        <div className="testimonial-avatar-info">
          <div className="avatar-thumb">
            <img src={"/img/images/avatar_img01.png"} alt="" />
          </div>

          <div className="avatar-content">
            <h2 className="title">{item.name}</h2>

            <p>{item.designation}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialAreaOneItem;
