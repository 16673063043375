import React, { useEffect, useState } from "react";
import FaqItem from "./FaqItem";
import axios from "axios";
import { API } from "../../config/urls";

const FaqAreaTwo = () => {
  const [data, setData] = useState();
  const GetFaq = () => {
    var config = {
      method: "get",
      url: API.getfaqs,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        setData(response.data.block);
      })

      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetFaq();
  }, []);
  return (
    <section className="faq-area-two pt-130 pb-130">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="faq-img-two" data-aos="fade-right">
              <img src={data?.image?.[0]?.image} alt="faqimg" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="faq-content">
              <div className="section-title-three mb-30">
                <span className="sub-title">{data?.sub_heading}</span>
                <h2 className="title">{data?.heading}</h2>
              </div>

              <div className="accordion" id="accordionExample">
                {data?.custom_fields?.map((x, index) => (
                  <FaqItem key={index} parentId={"accordionExample"} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqAreaTwo;
