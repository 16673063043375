import React, { useEffect, useState } from "react";
import axios from "axios";
import { API } from "../../config/urls";
import ContactPageArea from "../../components/Contact/ContactPageArea";
import ContactMapArea from "../../components/Contact/ContactMapArea";
import Offisignup from "../officeform";
import Blocks from "./block1";

const Detail = ({ data }) => {
  const [pricing, setPricing] = useState();

  useEffect(() => {
    const fetchPricing = async () => {
      try {
        const response = await axios.get(API.getStandardPackage);
        setPricing(response.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchPricing();
  }, []);

  return (
    <>
      <section>
        <div>
          {!data?.show_contact_form && !data?.show_signup_form && (
            <>
              <div>
                <Blocks
                  data={data}
                  pricing={pricing}
                  testimonials={data?.testimonials}
                />
              </div>
            </>
          )}
        </div>

        {data?.show_contact_form && (
          <>
            <ContactPageArea data={data} />
            <ContactMapArea />
          </>
        )}
        {data?.show_signup_form && <Offisignup />}
      </section>
    </>
  );
};

export default Detail;
