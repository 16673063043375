import React, { useState } from "react";
import cn from "classnames";

const FaqItem = ({ item, parentId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion-item">
      <h2 className="accordion-header">
        <button
          className={cn("accordion-button", { collapsed: !isOpen })}
          type="button"
          onClick={toggleCollapse}
          aria-expanded={isOpen}
        >
          {item.name}
        </button>
      </h2>

      <div className={cn("accordion-collapse collapse", { show: isOpen })}>
        <div className="accordion-body">
          <div
            className=" fs-6"
            dangerouslySetInnerHTML={{
              __html: item.custom_field_collections[0].char,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default FaqItem;
