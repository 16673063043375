import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { useLocation } from "react-router-dom";
import { localURL } from "../../config/urls";
import axios from "axios";
import BreadcrumbArea from "../../components/BreadcrumbArea/BreadcrumbArea";
import { imgconvert } from "../../helpers/imgconverter";
// import ServicesAreaTwoItem from "../../components/ServicesArea/ServicesAreaTwoItem";
// import SlickSlider from "../../components/SlickSlider/SlickSlider";
// import ServicesAreaThreeItem from "../../components/ServicesArea/ServicesAreaThreeItem";
// import { BestServiceItemsArray } from "../../components/BestServiceItem/BestServiceItemsArray";
// import ContactPageArea from "../../components/Contact/ContactPageArea";
// import ContactMapArea from "../../components/Contact/ContactMapArea";
// import Offisignup from "../officeform";
import SEO from "../../helpers/seo";
import Detail from "./Detail";

const DynamicPage = () => {
  const { pathname } = useLocation();
  console.log(pathname);

  const [data, setData] = useState();

  useEffect(() => {
    const Getdata = () => {
      var config = {
        method: "get",
        url: localURL + "/api/v1/cms/content" + pathname + "/",
        headers: {},
      };
      axios(config)
        .then(function (response) {
          console.log(response);
          setData(response.data);
        })

        .catch(function (error) {
          console.log(error);
        });
    };
    Getdata();
  }, [pathname]);
  return (
    <>
      <SEO
        title={data && data.seo && data.seo.title}
        description={data && data.seo && data.seo.description}
        image={imgconvert(data && data.seo && data.seo.image)}
        name={data && data.seo && data.seo.title}
        type={data && data.seo && data.type}
      />
      <Layout
        header={1}
        footer={1}
        headerClassName={"header-style-two"}
        topHeaderClassName={"header-top-two"}
      >
        <BreadcrumbArea
          title={pathname
            ?.slice(1)
            .replace(/-/g, " ")
            .replace(/\b\w/g, (x) => x.toUpperCase())}
          subtitle={pathname
            ?.slice(1)
            .replace(/-/g, " ")
            .replace(/\b\w/g, (x) => x.toUpperCase())}
        />
        <Detail data={data} />
      </Layout>
    </>
  );
};

export default DynamicPage;
