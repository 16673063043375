import React, { useEffect, useState, useMemo, useCallback } from "react";
import { jarallax } from "jarallax";
import axios from "axios";
import BannerTwo from "../../components/Banner/BannerTwo";
import ServicesAreaFour from "../../components/ServicesArea/ServicesAreaFour";
import IntroductionAreaTwo from "../../components/Introduction/IntroductionAreaTwo";
import ServicesAreaThree from "../../components/ServicesArea/ServicesAreaThree";
import TestimonialAreaTwo from "../../components/Testimonials/TestimonialAreaTwo";
import CounterAreaTwo from "../../components/CounterAreas/CounterAreaTwo";
import Layout from "../../layouts/Layout";
import { gsapTitleAnimation } from "../../lib/gsap-lib/gsapTitleAnimation";
import { localURL } from "../../config/urls";
import { useLocation } from "react-router-dom";
import SEO from "../../helpers/seo";
import { imgconvert } from "../../helpers/imgconverter";
import PageLoader from "../../components/PageLoader/PageLoader";
import PricingTable from "../../components/PricingArea/Pricing";
import FaqAreaTwo from "../../components/FaqArea/FaqAreaTwo";

const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
};

const HomeOne = () => {
  const { pathname } = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const config = useMemo(
    () => ({
      method: "get",
      url: `${localURL}/api/v1/cms/content/homepage/`,
      headers: {},
    }),
    []
  );

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios(config);
      setData(response.data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [config]);

  useEffect(() => {
    const debouncedFetchData = debounce(fetchData, 500);
    debouncedFetchData();
  }, [pathname, fetchData]);
  useEffect(() => {
    gsapTitleAnimation();
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);

  const { seo, block } = data || {};

  const seoTitle = seo?.title;
  const seoDescription = seo?.description;
  const seoImage = seo?.image;
  const seoType = seo?.type;

  if (loading) {
    return <PageLoader loading={loading} />;
  }

  if (error) {
    return (
      <div className="error-container">
        <p>Error: {error}</p>
      </div>
    );
  }

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={imgconvert(seoImage)}
        name={seoTitle}
        type={seoType}
      />
      <Layout
        header={1}
        footer={1}
        headerClassName={"header-style-two"}
        topHeaderClassName={"header-top-two"}
      >
        <BannerTwo data={block?.[0]} />
        <ServicesAreaFour data={block?.[1]} />
        <IntroductionAreaTwo data={block?.[2]} />
        <ServicesAreaThree data={block?.[3]} />
        <FaqAreaTwo />
        <TestimonialAreaTwo
          data={block?.[4]}
          className={"testimonial-area-two"}
          headerClassName={"animation-style2"}
          src={"/img/bg/testimonial_bg.jpg"}
        />
        <PricingTable />
        <CounterAreaTwo
          className={"counter-area-two"}
          src={"/img/telehealth/bg.jpg"}
        />
      </Layout>
    </>
  );
};

export default HomeOne;
